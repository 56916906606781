.skills-info-card {
    min-height: 23rem;
    border-radius: 0.65rem;
    border: 1.5px solid #4ebcc4;
    background: rgba(19, 17, 47, 0.398);
    backdrop-filter: blur(1rem);
}

.skills-info-card h6 {
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.8rem 2rem;
    background: linear-gradient(180deg, #ffffff 0%, #b7e2e9 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-bottom: 1.5px solid #4ebcc4;
}

.skills-info-content {
    padding: 2rem;
}

.skill-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.skill-info p {
    font-size: 1rem;
    font-weight: 500;
}

.skill-info.percentage {
    color: #085979;
}

.skill-progress-bg {
    width: 100%;
    height: 0.5rem;
    background: #214566;
    border-radius: 0.5rem;
    margin: 1rem 0;
    overflow: hidden;
}

.skill-progress {
    width: 0%;
    height: 0.5rem;
    background: linear-gradient(90deg, #8c9e3d -50%, #0397aa 50%);
    border-radius: 0.5rem;
    transition: all 0.5s ease-in-out;
}

@media (max-width: 1025px) {
    .skill-info.skills-info-card h6 {
        font-size: 1.1rem;
    }

    .skill-info p {
        font-size: 0.9rem;
    }

    .skills-info-content {
        padding: 1.5rem;
    }
}