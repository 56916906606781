.contact-details-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #4d799756;
    border-radius: 0.65rem;
    border: 1.5px solid #32a7af;
    padding: 1.5rem;
    margin-bottom: 2rem;
}

.contact-details-card .icon{
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    background: #c0d3db;
    margin-bottom: 0.8rem;
}

.icon img{
    width: 1.8rem;
    height: auto;
    object-fit: contain;
}

.contact-details-card p{
    font-size: 0.9rem;
    font-weight: 400;
}